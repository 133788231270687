<template>
  <div style="padding: 20px">
    <el-row :gutter="24">
      <el-col>
        <span class="analysis-header-title">历史累计</span>
        <span class="analysis-header-tip">截止{{ currentDay() }}</span>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="4">
        <div class="analysis-box-title">累计账号数</div>
        <div class="analysis-box-data">{{ analysisData.all_account_count }}</div>
      </el-col>
      <el-col :span="4"
        ><div class="analysis-box-title">累计0持仓账号数</div>
        <div class="analysis-box-data">{{ analysisData.all_zero_hold_account_count }}</div></el-col
      >
      <el-col :span="4"
        ><div class="analysis-box-title">累计非艺账号数</div>
        <div class="analysis-box-data">
          {{ analysisData.all_zero_artwork_account_count }}
        </div></el-col
      >
      <el-col :span="4"
        ><div class="analysis-box-title">累计1艺账号数</div>
        <div class="analysis-box-data">
          {{ analysisData.all_one_artwork_account_count }}
        </div></el-col
      >
      <el-col :span="4"
        ><div class="analysis-box-title">累计2艺账号数</div>
        <div class="analysis-box-data">
          {{ analysisData.all_two_artwork_account_count }}
        </div></el-col
      >
    </el-row>
    <el-row :gutter="24">
      <el-col :span="4"
        ><div class="analysis-box-title">累计交易账号数</div>
        <div class="analysis-box-data">{{ analysisData.all_trade_account_count }}</div></el-col
      >
      <el-col :span="4"
        ><div class="analysis-box-title">累计交易订单数</div>
        <div class="analysis-box-data">{{ analysisData.all_order_count }}</div></el-col
      >
      <el-col :span="4"
        ><div class="analysis-box-title">累计交易金额(CNY)</div>
        <div class="analysis-box-data">{{ analysisData.all_trade_amount_cny }}</div></el-col
      >
      <!-- TODO 隐藏部分 -->
      <!-- <el-col :span="4"
        ><div class="analysis-box-title">累计交易金额(BSV)</div>
        <div class="analysis-box-data">{{ analysisData.all_trade_amount_bsv }}</div></el-col
      > -->
    </el-row>
    <div style="margin-bottom: 10px;">
      <el-radio-group @change="handleDataChange" v-model="dataFrom">
        <el-radio-button label="account">账号数据</el-radio-button>
        <el-radio-button label="trad">交易数据</el-radio-button>
        <el-radio-button label="hold">持仓数据</el-radio-button>
      </el-radio-group>
      <el-radio-group @change="handleDataChange" v-model="range" style="margin-left:72px">
        <el-radio-button label="day">日维度</el-radio-button>
        <el-radio-button label="week">周维度</el-radio-button>
        <el-radio-button label="month">月维度</el-radio-button>
      </el-radio-group>
      <el-date-picker
        v-model="dateRange"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        style="margin:0 12px"
        :picker-options="timeOption"
      />
      <el-button type="primary" size="default" @click="handleDataChange">查询</el-button>
      <el-button type="primary" size="default" @click="handleExport">导出</el-button>
      <el-button type="default" size="default" @click="handleExportEmail">去重数据导出</el-button>
    </div>
    <el-table :data="list" border stripe v-if="dataFrom === 'account'">
      <el-table-column v-for="col in accountColumn" :prop="col.id" :key="col.id" :label="col.label">
      </el-table-column>
    </el-table>
    <el-table
      class="my-table"
      height="calc(100vh - 120px)"
      :data="list"
      border
      stripe
      v-if="dataFrom === 'trad'"
    >
      <el-table-column label="日期" prop="date"></el-table-column>
      <el-table-column label="交易订单数" prop="trade_order_count"></el-table-column>
      <el-table-column label="交易金额(CNY)" prop="trade_amount_cny"></el-table-column>
      <!-- TODO 隐藏部分 -->
      <!-- <el-table-column label="交易金额(BSV)" prop="trade_amount_bsv"></el-table-column> -->
      <el-table-column label="交易账号数据" key="trade_account">
        <el-table-column label="账户数" prop="trade_account_count"></el-table-column>
        <el-table-column label="新账号数" prop="trade_new_account_count"></el-table-column>
        <el-table-column label="召回账户数" prop="trade_back_account_count"></el-table-column>
        <el-table-column label="老账号数" prop="trade_old_account_count"></el-table-column>
      </el-table-column>
      <el-table-column label="买入账号数据" key="buy_account">
        <el-table-column label="账户数" prop="buy_account_count"></el-table-column>
        <el-table-column label="新账号数" prop="buy_new_account_count"></el-table-column>
        <el-table-column label="召回账户数" prop="buy_back_account_count"></el-table-column>
        <el-table-column label="老账号数" prop="buy_old_account_count"></el-table-column>
      </el-table-column>
      <el-table-column label="卖出账号数据" key="sell_account">
        <el-table-column label="账户数" prop="sell_account_count"></el-table-column>
        <el-table-column label="新账号数" prop="sell_new_account_count"></el-table-column>
        <el-table-column label="召回账户数" prop="sell_back_account_count"></el-table-column>
        <el-table-column label="老账号数" prop="sell_old_account_count"></el-table-column>
      </el-table-column>
    </el-table>
    <el-table :data="list" border stripe v-if="dataFrom === 'hold'">
      <el-table-column label="日期" prop="date"></el-table-column>
      <el-table-column label="0持仓账户数" key="hold_0">
        <el-table-column label="账户数" prop="hold_0"></el-table-column>
        <el-table-column label="新账号数" prop="new_account_hold_0"></el-table-column>
        <el-table-column label="召回账户数" prop="back_account_hold_0"></el-table-column>
        <el-table-column label="老账号数" prop="old_account_hold_0"></el-table-column>
      </el-table-column>
      <el-table-column label="非艺账户数" key="not_artist">
        <el-table-column label="账户数" prop="hold_not_artist"></el-table-column>
        <el-table-column label="新账号数" prop="new_account_not_artist"></el-table-column>
        <el-table-column label="召回账户数" prop="back_account_hold_not_artist"></el-table-column>
        <el-table-column label="老账号数" prop="old_account_hold_not_artist"></el-table-column>
      </el-table-column>
      <el-table-column label="1艺账户数" key="hold_1">
        <el-table-column label="账户数" prop="hold_1"></el-table-column>
        <el-table-column label="新账号数" prop="new_account_hold_1"></el-table-column>
        <el-table-column label="召回账户数" prop="back_account_hold_1"></el-table-column>
        <el-table-column label="老账号数" prop="old_account_hold_1"></el-table-column>
      </el-table-column>
      <el-table-column label="2艺账户数" key="hold_2">
        <el-table-column label="账户数" prop="hold_2"></el-table-column>
        <el-table-column label="新账号数" prop="new_account_hold_2"></el-table-column>
        <el-table-column label="召回账户数" prop="back_account_hold_2"></el-table-column>
        <el-table-column label="老账号数" prop="old_account_hold_2"></el-table-column>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="'去重数据导出'"
      :visible.sync="showEmail"
      width="20%"
      @close="showEmail = false"
      @destroy-on-close="true"
    >
      <el-input v-model="exportEmail" placeholder="请输入接收邮箱"></el-input>
      <!--   @blur="inputValidator" -->
      <div class="dialog-tip">去重统计需要时间,完成后将发送至所填邮箱</div>
      <span slot="footer">
        <el-button @click="showEmail = false">取 消</el-button>
        <el-button type="primary" @click="inputValidator">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  exportAccountData,
  exportHoldData,
  exportTradData,
  getAccountData,
  getHistoryData,
  getHoldData,
  getTradData,
} from "@/api/nft.js";
import dayjs from "dayjs";
export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      range: "day",
      dataFrom: "account",
      list: [],
      exportEmail: "",
      showEmail: false,
      dateRange: [],
      accountColumn: [
        {
          id: "date",
          label: "日期",
        },
        {
          id: "total_account",
          label: "账号数",
        },
        {
          id: "new_account",
          label: "新账号数",
        },
        {
          id: "back_account",
          label: "召回账号数",
        },
        {
          id: "old_account",
          label: "老账号数",
        },
      ],
      analysisData: {
        all_account_count: 12345678,
        all_zero_hold_account_count: 12345678,
        all_zero_artwork_account_count: 12345678,
        all_one_artwork_account_count: 12345678,
        all_two_artwork_account_count: 12345678,
        all_trade_account_count: 12345678,
        all_order_count: 12345678,
        all_trade_amount_cny: 12345678,
        all_trade_amount_bsv: 12345678,
      },
      timeOption: {
        // 大于2021-10-1 至今
        disabledDate(date) {
          return dayjs(date).isBefore(dayjs("2021-10-1")) || dayjs(date).isAfter(dayjs());
        },
      },
    };
  },
  mounted() {
    this.fetchData();
    this.getHistoryData();
  },
  methods: {
    currentDay() {
      return dayjs()
        .subtract(1, "day")
        .format("YYYY/MM/DD");
    },
    setTimeStart(time) {
      return time
        ? dayjs(time)
            .startOf("day")
            .unix()
        : "";
    },
    setTimeEnd(time) {
      return time
        ? dayjs(time)
            .endOf("day")
            .unix()
        : "";
    },

    fetchData() {
      let params = { page: this.page, page_size: this.pageSize, dimension: this.range };
      if (Object.values(this.dateRange).length) {
        params.start_at = this.setTimeStart(this.dateRange[0]);
        params.end_at = this.setTimeEnd(this.dateRange[1]);
      }
      if (this.dataFrom === "trad") {
        this.getTradData(params);
      } else if (this.dataFrom === "hold") {
        this.getHoldData(params);
      } else {
        this.getAccountData(params);
      }
    },
    exportData(isEmail) {
      let params = {
        dimension: this.range,
      };
      if (this.range === "day") {
        // 7day
        let start = dayjs()
          .subtract(7, "day")
          .format("YYYY-MM-DD");
        let end = dayjs().format("YYYY-MM-DD");
        params.start_at = this.setTimeStart(start);
        params.end_at = this.setTimeEnd(end);
      } else if (this.range === "week") {
        // 7 week
        let start = dayjs()
          .subtract(7, "week")
          .format("YYYY-MM-DD");
        let end = dayjs().format("YYYY-MM-DD");
        params.start_at = this.setTimeStart(start);
        params.end_at = this.setTimeEnd(end);
      } else if (this.range === "month") {
        // 7month
        let start = dayjs()
          .subtract(7, "month")
          .format("YYYY-MM-DD");
        let end = dayjs().format("YYYY-MM-DD");
        params.start_at = this.setTimeStart(start);
        params.end_at = this.setTimeEnd(end);
      }
      if (this.dateRange && Object.values(this.dateRange).length) {
        params.start_at = this.setTimeStart(this.dateRange[0]);
        params.end_at = this.setTimeEnd(this.dateRange[1]);
      }
      if (isEmail) {
        params.email = this.exportEmail;
      }
      console.log("=======params", this.dateRange, params, this.range);
      if (this.dataFrom === "trad") {
        this.exportTradData(isEmail, params);
      } else if (this.dataFrom === "hold") {
        this.exportHoldData(isEmail, params);
      } else {
        this.exportAccountData(isEmail, params);
      }
    },
    async getHistoryData() {
      try {
        const { data } = await getHistoryData();
        const res = data?.data;
        this.analysisData = res;
      } catch (error) {
        console.log(error);
      }
    },
    async getTradData(params) {
      try {
        const { data } = await getTradData(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    async getAccountData(params) {
      try {
        const { data } = await getAccountData(params);
        const { total = 0, list = [] } = data?.data;
        this.total = total;
        this.list = [...list];
      } catch (error) {
        console.log(error);
      }
    },
    async getHoldData(params) {
      try {
        const { data } = await getHoldData(params);
        const { total = 0, list = [] } = data?.data;
        this.total = total;
        this.list = [...list];
      } catch (error) {
        console.log(error);
      }
    },
    async exportTradData(isEmail, params) {
      try {
        const { data } = await exportTradData(params);
        const res = data?.data;
        this.showEmail = false;
        if (isEmail) {
          res.msg ? this.$message(res.msg) : "";
        } else {
          res.url ? window.open(res.url) : this.$message.error("暂无下载链接");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async exportAccountData(isEmail, params) {
      try {
        const { data } = await exportAccountData(params);
        const res = data?.data;
        this.showEmail = false;
        if (isEmail) {
          res.msg ? this.$message(res.msg) : "";
        } else {
          res.url ? window.open(res.url) : this.$message.error("暂无下载链接");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async exportHoldData(isEmail, params) {
      try {
        const { data } = await exportHoldData(params);
        const res = data?.data;
        this.showEmail = false;
        if (isEmail) {
          res.msg ? this.$message(res.msg) : "";
        } else {
          res.url ? window.open(res.url) : this.$message.error("暂无下载链接");
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleExport() {
      this.exportData(false);
    },
    handleExportEmail() {
      this.showEmail = true;
    },
    inputValidator() {
      const eamilRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      let validator = eamilRegex.test(this.exportEmail) && this.exportEmail;
      if (!validator) {
        this.$message.error("请输入正确邮箱地址!");
      } else {
        this.exportData(true);
      }
      console.log(this.exportEmail, validator);
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleDataChange() {
      this.pageSize = 20;
      this.page = 1;
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
  .analysis-header-title {
    font-size: 20px;
    font-weight: 600;
  }
  .analysis-header-tip {
    color: rgba(0, 0, 0, 0.65);
    margin-left: 12px;
  }
  .analysis-box-title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
  }
  .analysis-box-data {
    font-size: 24px;
    text-align: center;
  }
}
</style>
